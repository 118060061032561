@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 241 84% 34%;
    --primary-dark: 237 75% 20%;
    --primary-foreground: 210 40% 98%;
    --primary-weak: 240 50% 84%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --secondary-text: 218 100% 73%;

    --tertiary: 48 98% 51%;
    --tertiary-weak: 50 100% 73%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --neutral: 208 13% 63%;
    --neutral-medium: 195 15% 95%;
    --neutral-disabled: 208 13% 63%;
    --neutral-text: 207 19% 27%;
    --neutral-text-strong: 220 11% 5%;

    --blue-300: 218 47% 41%;
    --blue-400: 241 90% 20%;
    --blue-500: 241 84% 34%;
    --blue-700: 237 75% 20%;

    --green-300: 140 58% 80%;

    --red-500: 347 93% 72%;

    --yellow-500: 48 100% 65%;
    --yellow-700: 40 100% 55%;

    --success: 161 69% 36%;
    --error: 349 78% 39%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --font-sans: 'Roboto';
    --font-serif: 'Libre Franklin';
    --font-mono: 'Inter';

    --chip-neutral: 200 17% 95%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

html, body, #root {
  height: 100%;
}

.bg-hero-pattern {
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(360deg, #000D8E 0%, #000996 31%, rgba(44, 51, 142, 0) 100%),url('./assets/background.svg');
}

.bg-illustration-dark-blue {
  background: #100E9D url('./assets/illustration.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-illustration-light-blue {
  background: #D4E5FF url('./assets/illustration-weak.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-illustration-white {
  background: #FFFFFF url('./assets/illustration-weak.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#terms ol {
  list-style-type: disc;
}

#terms ul {
  list-style-type: disc;
}
